import {
    dbGet,
    dbPut,
    inkoprintGet,
    inkoprintPost,
    inkoprintPut,
    isAdm,
    isBelgium,
    loggedUserName,
    transSmartGet,
} from ".";
import Cookies from "universal-cookie";

const cookies = new Cookies()

const k = {
    id: '',
    reference: '',
    description: '',
    amount: '',
    orderdate: '',
    deliverydate: '',
    remainingDays: '',
    supplierdata: '',
    Status: ''
}
const dataTemp = {
    data: [{
        id: '123',
        reference: '123ref',
        description: 'test',
        amount: 12,
        orderdate: "20-08-2022",
        deliverydate: "20-09-2022",
        supplierdata: "20-08-2022",
        Status: "Rejected",
    }]
}

export async function getLiveOrders() {
    try {
        const response = await inkoprintGet('getOrders');
        return response.data || [];
    } catch (error) {
        console.log('An error occurred on getLiveOrders method');
        console.log(error);
        return [];
    }
}

export async function getOrders(country) {
    let getUrl = isBelgium(country) ? "neworders" : "neworders-es";

    if (!isAdm()) {
        getUrl += "?supplier_name=" + loggedUserName();
    }

    try {
        const response = await dbGet(getUrl);
        const data = {data: response.data.data.map(d => ({...d.attributes, id: d.id}))}
        return data;
        // return dataTemp
    } catch (error) {
        console.log('An error occurred on getOrders method');
        console.log(error);
        return [];
    }
}

export async function getInkoprintOrders(country) {
    let getUrl = "getOrders";

    try {
        const response = await inkoprintGet(getUrl, country);
        const mappedOrders = []
        response.data.map(d => {
            const keys = Object.keys(d.order_lines)
            keys.forEach(k => mappedOrders.push(d.order_lines[k]))
        })
        return {data: mappedOrders};
    } catch (error) {
        console.log('An error occurred on getInkoprintOrders method');
        console.log(error);
        if (error?.response?.status === 401) throw new Error("No valid credentials to fetch data, please re-login")
        else throw error
    }
}

export async function getOrderById(id, country) {
    let getUrl = (isBelgium(country) ? "neworders" : "neworders-es") + `/${id}?reference=true`;

    try {
        const response = await dbGet(getUrl);
        const data = response.data.data.map(d => ({...d.attributes, id: d.id}))
        return {data};
    } catch (error) {
        console.log('An error occurred on getOrderById method');
        console.log(error);
        return [];
    }
}

export async function getSuppliers() {
    try {
        const response = await dbGet('suppliers');
        const suppliers = response.data.data.map(d => ({id: d.id, ...d.attributes}))
        return {data: suppliers} || {data: []}
    } catch (error) {
        console.log('An error occurred on getSuppliers method');
        console.log(error);
        return {data: []};
    }
}

export async function pushLabelToTransSmart(strapiId, data, country) {
    try {
        const url = (isBelgium(country) ? "neworders/" : "neworders-es/") + strapiId;
        const response = await dbPut(url, data);
        return response;
    } catch (error) {
        console.log('An error occurred on pushLabelToTransSmart method');
        console.log(error);
        return [];
    }
}

export async function pushLabelToInkoprint(data, country) {
    try {
        const response = await inkoprintPut('getOrders', data, country);
        return response || [];
    } catch (error) {
        console.log('An error occurred on pushLabelToInkoprint method');
        console.log(error);
        return [];
    }
}

export async function pushLabelToDb(strapiId, data, country) {
    try {
        const url = (isBelgium(country) ? "neworders/" : "neworders-es/") + strapiId;
        const response = await dbPut(url, data);
        return response || [];
    } catch (error) {
        console.log('An error occurred on pushLabelToDb method');
        console.log(error);
        return [];
    }
}

export async function postRejectedTransSmart(strapiId, data, country) {
    try {
        const response = await inkoprintPut(strapiId, data, country);
        return response || [];
    } catch (error) {
        console.log('An error occurred on postRejectedTransSmart method');
        console.log(error);
        return [];
    }
}

export async function sendOrderEmailInkoprint(data, country) {
    try {
        const response = await inkoprintPost('sendmail', data, country);
        return response || [];
    } catch (error) {
        console.log('An error occurred on sendOrderEmailInkoprint method');
        console.log(error);
        return [];
    }
}

export async function getUsers() {
    try {
        const response = await dbGet('users');
        return response || [];
    } catch (error) {
        console.log('An error occurred on sendOrderEmailInkoprint method');
        console.log(error);
        return [];
    }
}

export async function downloadLabelsTransSmart(id) {
    try {
        const response = await transSmartGet("v2/prints/BUROFORM/" + id + "?rawJob=true");
        return response || [];
    } catch (error) {
        console.log('An error occurred on sendOrderEmailInkoprint method');
        console.log(error);
        return [];
    }
}