import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Space, Spin, Button } from 'antd';

import { StepsMod } from '../modules';
import table from '../modules/table';
import { getOrders, getCountryCode } from '../services';
import { formatRoute, Routes } from '../constants';

export class Shipping extends React.Component {
    constructor(props) {
        super(props);
        this.state = { inkoorders: [], loading: false }
    }

    componentDidMount = async () => {
        const locationArr = this.props.location.pathname.split('-')
        const country = locationArr[locationArr.length - 1] === 'es' ? "ES": "BE"
        const response = await getOrders(country);
        this.setState({ inkoorders: response.data, loading: true, });
    };

    render() {
        const { inkoorders } = this.state;
        const locationArr = this.props.location.pathname.split('-')
        const country = locationArr[locationArr.length - 1] === 'es' ? "ES": "BE"

        var dataSource = table(inkoorders, "Shipping");

        return <>
            <StepsMod stepcounter="3" />
            {this.state.loading ?
                <Table dataSource={dataSource} columns={[
                    { title: "Ref.", dataIndex: "reference", key: "reference" },
                    { title: "Description", dataIndex: "description", key: "description" },
                    { title: "Order date", dataIndex: "order_date", key: "order_date" },
                    { title: "Shipping date", dataIndex: "delivery_date", key: "delivery_date" },
                    { title: "Remaining days", dataIndex: "remainingDays", key: "remainingDays" },
                    { title: "Supplier", dataIndex: "supplier", key: "supplier" },
                    { title: "Status", dataIndex: "status", key: "status" },
                    {
                        title: "Action", key: "action", render: (text, record) => (
                            <Space size="middle">
                                <Link to={formatRoute(Routes.ToBeDone, { country: getCountryCode(country), id: record.reference })}>
                                    <Button type="primary" value="small">
                                        Open
                                    </Button>
                                </Link>
                            </Space>
                        ),
                    }
                ]} />
                :
                <Spin />
            }
        </>;
    }
}
