import React from 'react'
import {Tag} from 'antd';

function RemainingDays(day) {
    if(!day) return ''
    var today = new Date();
    var parts = day.split('-');
    var lastDay = new Date(parts[2], parts[1] - 1, parts[0]);

    var days = Math.ceil((lastDay - today) / (1000 * 3600 * 24));

    if (days < 0) return <Tag color="#f50">{days}</Tag>;
    if (days === 0) return <Tag color="#87d068">{days}</Tag>
    else return days;
}


function colorStatus(element) {
    if (element.Status === "Rejected") {
        return <div style={{color: 'red'}}>{element.Status}</div>
    } else {
        return element.Status
    }
}

//ALL TABLES ARE PERFORMED BY THIS FUNCTION, EXCEPT THE "LIVE ORDERS" TABLE
//BECAUSE "LIVE ORDERS" TABLE. ORDERS ARE FROM ANOTHER DATABASE AND HAS OTHER PARAMETERS
function table(inkoorders, status, rejected) {

    var dataSource = [];

    if (inkoorders && inkoorders?.length) {
        inkoorders
            .sort((a, b) => b?.delivery_date - a?.delivery_date)
            .filter(element => element.Status === status || element.Status === rejected)
            .map(element => {
                if (element.supplier_name !== null) {
                    var supplierdata = element.supplier_name;

                } else {
                    supplierdata = "No supplier"
                }

                if (status !== "Done") {
                    dataSource.push(
                        {
                            key: element.id,
                            reference: element.reference,
                            description: element.amount + "x " + element.description,
                            order_date: element.orderdate,
                            delivery_date: element.deliverydate,
                            remainingDays: RemainingDays(element.deliverydate),
                            supplier: supplierdata,
                            status: colorStatus(element)
                        }
                    )
                } else {
                    let TranssmartOptionsReference = element.TranssmartOptions && element.TranssmartOptions ? element.TranssmartOptions.reference : ''
                    dataSource.push(
                        {
                            key: element.id,
                            reference: element.reference,
                            description: element.amount + "x " + element.description,
                            order_date: element.orderdate,
                            delivery_date: element.deliverydate,
                            TSreference: TranssmartOptionsReference,
                            status: element.Status
                        }
                    )
                }

                return dataSource
            });
    }

    return dataSource;
}

export default table