import React from "react";
import {withRouter} from "react-router-dom";
import moment from 'moment';
import {
    Row,
    Col,
    Button,
    Select,
    Timeline,
    Divider,
    Card,
    Typography,
    Tabs,
    InputNumber,
    Cascader,
    DatePicker,
    Alert,
    Space,
    Spin
} from "antd";

import {DownloadableFiles, ShowCalculations} from '../modules';
import {
    pushLabelToTransSmart,
    getOrderById,
    postRejectedTransSmart,
    transSmartPost,
    pushLabelToDb,
    pushLabelToInkoprint,
    sendOrderEmailInkoprint
} from "../services";

const {Option} = Select;
const {TabPane} = Tabs;
const {Title} = Typography;

const carrierOptions = [
    {
        value: "DHP",
        label: "DHL Parcel",
        children: [
            {
                value: "EUROPLUS",
                label: "EuroPlus",
            },
        ],
    },
    {
        value: "UPS",
        label: "UPS",
        children: [
            {
                value: "STANDARD",
                label: "Standard",
            },
        ],
    },
    {
        value: "DPD",
        label: "DPD",
        children: [
            {
                value: "CLASSIC",
                label: "classic",
            },
        ],
    },
    {
        value: "direct",
        label: "Direct shipment",
    },
    {
        value: "otherparcel",
        label: "With other parcel",
    },
];

class ToBeDoneComponent extends React.Component {
    constructor(props) {
        super(props);
        this.changeCarrier = this.changeCarrier.bind(this);

        this.state = {
            strapiId: null,
            order: [],
            delivery_address: {},
            sender_address: {},
            calculation: [],
            description: {},
            uploads: [],
            orderID: null,
            readerror: [],
            calculatedPrice: null,
            carrier: null,
            servicelevel: null,
            courierDate: null,
            strapiID: null,
            courierBox: null,
            TSreference: null,
            renderAlertFail: false,
            errorDescription: "",
            loading: true
        };
    }

    changeCarrier = (value) => {
        // console.log(value);
        let newcarrier = "";
        value.forEach((item) => {
            let string = JSON.stringify(item);
            string = string.replace(/"/g, "");
            if (newcarrier !== "") {
                this.setState({servicelevel: string});
            }
            newcarrier += string;
        });
        let string = JSON.stringify(value[0]);
        string = string.replace(/"/g, "");
        this.setState({carrier: string});
        // console.log(newcarrier);
        // console.log(this.state.servicelevel);
    };

    courierDate = (date) => {
        if (date !== null) {
            let day = date.date();
            let month = date.month() + 1;
            if (month < 10) {
                month = "0" + month;
            }
            if (day < 10) {
                day = "0" + day;
            }
            let year = date.year();
            this.setState({courierDate: year + "-" + month + "-" + day});
            console.log(day + "-" + month + "-" + year);
        } else return;
    };

    getLabels = () => {
        if (this.state.carrier !== "otherparcel") {
            this.bookStrapi();
        }
    };

    bookStrapi = async () => {
        var orderJson = {};
        var inkoprintID = this.state.orderID;
        var strapiId = this.state.strapiId;

        //MAKE VARIABLE FOR PACKAGES
        var aantal = document.getElementById("aantalcolli").value;
        var packages = [];
        for (let i = 0; i < aantal; i++) {
            packages.push({
                lineNo: i + 1,
                packageType: this.state.courierBox,
                description: "",
                quantity: 1,
                carrier: this.state.carrier,
                measurements: {
                    length: 30.0,
                    width: 20.0,
                    height: 10.0,
                    weight: 1.0,
                    linearUom: "CM",
                    massUom: "KG",
                },
            });
        }
        var pack = {package: packages,};
        const country = this.props.match.params.country === 'ES'? 'ES': 'BE';
        let response = await pushLabelToTransSmart(strapiId, {data: pack}, country);

        var orderresponse = {};

        response = await getOrderById(inkoprintID, country);

        orderresponse = response.data[0];
        this.setState({
            TSreference: orderresponse.reference + "_" + orderresponse.id,
        });
        var TSreference = orderresponse.reference + "_" + orderresponse.id;
        //CHECK COSTCENTER
        var portal = orderresponse.portal;
        var costcenter = "*";
        if (portal === "inko") {
            costcenter = "004";
        }

        orderJson = JSON.stringify([
            {
                reference: TSreference,
                carrier: this.state.carrier,
                costCenter: costcenter,
                value: 25,
                valueCurrency: "EUR",
                pickupDate: this.state.courierDate,
                service: "NON-DOCS",
                serviceLevelTime: this.state.servicelevel,
                serviceLevelOther: "",
                incoterms: "CPT",
                numberOfPackages: parseInt(aantal),
                measurements: {
                    length: 30.0,
                    width: 20.0,
                    height: 10.0,
                    weight: 1.0,
                    linearUom: "CM",
                    massUom: "KG",
                },
                addresses: [
                    {
                        type: "RECV",
                        name: orderresponse.receiver.name,
                        addressLine1: orderresponse.receiver.addressLine1,
                        addressLine2: orderresponse.receiver.addressLine2,
                        houseNo: orderresponse.receiver.houseNo,
                        city: orderresponse.receiver.city,
                        zipCode: orderresponse.receiver.zipCode,
                        country: orderresponse.receiver.country,
                        contact: orderresponse.receiver.contact,
                        email: orderresponse.receiver.email,
                    },
                    {
                        type: "SEND",
                        name: orderresponse.sender.contact,
                        addressLine1: orderresponse.sender.addressLine1,
                        addressLine2: orderresponse.sender.addressLine2,
                        houseNo: orderresponse.sender.houseNo,
                        city: orderresponse.sender.city,
                        zipCode: orderresponse.sender.zipCode,
                        country: orderresponse.sender.country,
                        contact: orderresponse.sender.contact,
                        email: orderresponse.sender.email,
                    },
                ],
                packages: packages,
            },
        ]);
        /// DONE UPDATING INKOSMART DATABASE (STRAPI)

        const result = await transSmartPost(orderJson);

        console.log(result);

        if (result.status !== "BAD_REQUEST") {
            const locationArr = this.props.location.pathname.split('-')
            const country = locationArr[locationArr.length - 1] === 'es' ? "ES": "BE"
            console.log("Label created on inkossmart.");

            var inkojson = {
                "order_id": inkoprintID,
                "order_status": "IS_done",
                "order_reference": orderresponse.reference,
                "tracking_code": result[0].trackingUrl
            };
            await pushLabelToInkoprint(inkojson, country);

            response = await pushLabelToDb(strapiId, {
                data: {
                    Status: "Done",
                    TranssmartOptions: {
                        packages: packages,
                        reference: TSreference,
                        tracktrace: result[0].trackingUrl,
                        statuscode: result[0].shipmentStatus.statusCode
                    },
                }
            }, country);

            var dataMail = {
                email_from: "supplier@inkoprint.be",
                email_to: "hello@inkoprint.be",
                subject: `Order: ${inkoprintID} - sent for delivery. `,
                message: `Order ${inkoprintID} has been created for delivery.`,
            };

            await sendOrderEmailInkoprint(dataMail, country);
        } else if (result.status === "BAD_REQUEST") {
            this.setState({
                renderAlertFail: true,
                errorDescription: result.details[0].errorDescription,
            });

            await postRejectedTransSmart(strapiId, {
                TranssmartOptions: {
                    statuscode: "Rejected in Transsmart",
                    rejected_transsmart: this.state.errorDescription
                },
            }, country);

            console.log(result.details[0].errorDescription);
        }
    };

    componentDidMount = async () => {
        const id = this.props.match.params.id;
        const country = this.props.match.params.country === 'ES'? 'ES': 'BE';
        const response = await getOrderById(id, country);
        const order = response.data[0];

        this.setState({
            strapiId: order.id,
            orderID: id,
            order: order,
            delivery_address: order.receiver,
            sender_address: order.sender,
            calculation: order.fullorder,
            description: order.description_long,
            uploads: order.uploads,
            loading: false
        });

        // SET CALCULATIONS IF MORE THAN ONE
        if (order.calculation) {
            if (typeof order.calculation["aantal vel papier"].eerste === "object") {
                this.setState({calculation: order.calculation["aantal vel papier"],});
            } else {
                this.setState({calculation: order.calculation});
            }
        }
    }

    // Can not select days before today and today
    disabledDate(current) {
        return current && current < moment().endOf('day');
    }

    render() {
        const {
            orderID,
            order,
            sender_address,
            delivery_address,
            calculation,
            description,
            loading
        }
            = this.state;

        if (loading) return <Spin/>;

        if (!order) return <></>;

        var today = new Date();
        var parts = order.deliverydate.split("-");
        var lastDay = new Date(parts[2], parts[1] - 1, parts[0]);
        var remainingDays = Math.ceil((lastDay - today) / (1000 * 3600 * 24));
        return (
            <>
                <Row>
                    <Col flex={10}>
                        <Title>
                            {orderID} - {order.description}
                        </Title>
                    </Col>
                </Row>

                <Tabs defaultActiveKey="1" onChange={this.callBack}>
                    <TabPane tab="Summary" key="1">
                        <Title level={2}>Summary</Title>

                        <Row>
                            <Col flex={1}>
                                <Divider orientation="left">Date information</Divider>
                                <br/>
                                <Timeline>
                                    <Timeline.Item>
                                        Order date was {order.orderdate}
                                    </Timeline.Item>
                                    <Timeline.Item>
                                        Shipping date {order.deliverydate}
                                    </Timeline.Item>
                                </Timeline>
                                <br/>

                                <Divider orientation="left">Price information</Divider>

                                <p>
                                    <b>Price: </b>
                                    {order.price}
                                </p>

                                <br/>
                                <Divider orientation="left">Shipping information</Divider>
                                <p>
                                    <b>Remaining days to complete: </b>
                                    {remainingDays}
                                </p>
                            </Col>
                            <Col flex={1}>
                                <Card title="Product information">
                                    <h3>{order.amount}x</h3>
                                    {Object.keys(description ? description : "").map(
                                        (keyName, i) => (
                                            <li key={i}>
                        <span className="input-label">
                          <b>{keyName}</b> {description[keyName]}
                        </span>
                                            </li>
                                        )
                                    )}
                                </Card>
                                <br/>
                                <Card title="Make shipment">
                                    <h3>
                                        Preferred shipping method:{" "}
                                        <strong className="preferred-method:">
                                            {order.preferred_shipping_method}
                                        </strong>
                                    </h3>
                                    <h3>Choose options</h3>
                                    <Space size={10}>
                                        <InputNumber min={1} max={10} defaultValue={0} id="aantalcolli"/>
                                        <Select style={{width: 120}}
                                                onChange={(value) => this.setState({courierBox: value})}
                                        >
                                            <Option value="BOX">BOX</Option>
                                            <Option value="PALLET">PALLET</Option>
                                            <Option value="KOKER">TUBE</Option>
                                        </Select>
                                        <Cascader options={carrierOptions} onChange={this.changeCarrier}
                                                  placeholder="Select Courier"/>
                                        <DatePicker onChange={this.courierDate} disabledDate={this.disabledDate}/>
                                        <Button type="primary" onClick={this.getLabels}>
                                            Make labels
                                        </Button>
                                    </Space>
                                </Card>

                                {/* failure alert*/}
                                {this.state.renderAlertFail ? (
                                    <Alert
                                        message="Order failed"
                                        description={this.state.errorDescription}
                                        type="error"
                                        closable
                                    />
                                ) : (
                                    ""
                                )}

                                <br/>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Print information" key="2">
                        <Title level={2}>Print information</Title>
                        <Row>
                            <Col flex={1}>
                                <DownloadableFiles order={order}/>
                                <br/>
                                <Card title="Calculation outcome">
                                    <ShowCalculations calc={calculation}/>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Delivery" key="3">
                        <Title level={2}>Delivery information</Title>

                        <Row>
                            <Col flex={1}>
                                <Card title="Delivery address">
                                    {Object.keys(delivery_address ? delivery_address : "").map(
                                        (keyName, i) => (
                                            <li key={i}>
                        <span className="input-label">
                          <b>{keyName}</b> {delivery_address[keyName]}
                        </span>
                                            </li>
                                        )
                                    )}
                                </Card>
                            </Col>
                            <Col flex={1}>
                                <Card title="Sender address">
                                    {Object.keys(sender_address ? sender_address : "").map(
                                        (keyName, i) => (
                                            <li className="travelcompany-input" key={i}>
                        <span className="input-label">
                          <b>{keyName}</b> {sender_address[keyName]}
                        </span>
                                            </li>
                                        )
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>
            </>
        );
    }
}

export const ToBeDone = withRouter(ToBeDoneComponent);
