import React from 'react';
import Cookies from 'universal-cookie';
import axios from "axios";
import {Button, Modal} from 'antd';

import {isAdm, navigate} from '../services';
import {dbUrl, inkoprintEsUrl, inkoprintUrl} from "../constants";
import {useHistory, useLocation} from "react-router-dom";

const {confirm} = Modal;

const cookies = new Cookies();

const Statuses = {
    Rejected: 'rejected',
    ChangeShipDate: 'changeshipdate',
    RejectedOrderChecking: 'rejectedOrderChecking',
    AcceptedOrder: 'acceptedOrder',
}

export function ChangeStatusButton(props) {
    const {statusData} = props;
    const location = useLocation()
    const history = useHistory();

    const dataInko = {
        "order_id": statusData.order_id,
        "order_status": statusData.order_status
    };
    const dataStrapi = {"Status": statusData.Status};
    const dataMail = {
        email_from: "supplier@inkoprint.be",
        email_to: "hello@inkoprint.be",
        subject: `Order: ${statusData.order_id} - ${statusData.nextStep}. `,
        message: `Order status ${statusData.order_id} update for ${statusData.nextStep}.`,
    };

    const updateOrder = () => {
        const isAdmin = isAdm()
        const matchesES = location?.pathname?.match(/\/ES\//)
        const country = matchesES?.length && matchesES[0] ? 'ES' : 'BE';

        const countryOrder = isAdmin ? country : statusData.Site;
        let newUrl;

        //CREATING THE INKOPRINT URL
        countryOrder === 'BE' ? newUrl = inkoprintUrl + 'getOrders' : newUrl = inkoprintEsUrl + 'getOrders';


        //CREATING THE STRAPI URL (INKOSMART)
        let inkosmarturl = countryOrder === 'BE' ? dbUrl + 'neworders/' : dbUrl + 'neworders-es/';
        ;
        statusData.method === 'put' ? (inkosmarturl += statusData.strapiID) : (inkosmarturl += "");

        //CREATING VARIABLE FOR PAGE REDIRECT
        const forwardURL = chooseForwardUrl(statusData.nextStep, countryOrder)

        let newDataStrapi = dataStrapi;
        let newDataInko = dataInko;
        let newDataMail = dataMail;

        switch (statusData.trigger) {
            case Statuses.Rejected:
                newDataInko = {
                    ...dataInko,
                    "order_status_message": statusData.order_message
                };
                newDataStrapi = {
                    ...dataStrapi,
                    "reason_rejected": statusData.order_message
                };
                newDataMail = {
                    ...dataMail,
                    subject: `Order: ${statusData.order_id}  was rejected. `,
                    message: `Order ${statusData.order_id} was rejected because ${statusData.order_message}`
                };
                break;
            case Statuses.ChangeShipDate:
                newDataInko = {
                    ...dataInko,
                    "new_delivery_date": statusData.order_new_dateYMD,
                    "new_delivery_date_notes": statusData.order_new_date_message,

                };
                newDataStrapi = {
                    ...dataStrapi,
                    "new_delivery_date_notes": statusData.order_new_date_message,
                    "deliverydate": statusData.order_new_date,
                };
                newDataMail = {
                    ...dataMail,
                    subject: `Order: ${statusData.order_id} has changed the delivery date. `,
                    message: `The delivery date for order ${statusData.order_id} was changed to ${statusData.order_new_dateYMD} because ${statusData.order_new_date_message}`
                }
                break;
            case Statuses.RejectedOrderChecking:
                newDataInko = {
                    ...dataInko,
                    "order_status_message": statusData.order_message
                };
                newDataMail = {
                    ...dataMail,
                    subject: `Order: ${statusData.order_id}  was rejected. `,
                    message: `Order ${statusData.order_id} was rejected because ${statusData.order_new_date_message}`
                };
                break;
            case Statuses.AcceptedOrder:
                newDataStrapi = statusData.objPushOrder;
                break;
            default:
                break;
        }

        if (statusData.trigger !== Statuses.RejectedOrderChecking) {
            // UPDATE STRAPI
            const configStrapi = {
                method: statusData.method,
                url: inkosmarturl,
                headers: {
                    'Authorization': cookies.get('JWT'),
                    'Content-Type': 'application/json'
                },
                data: {data: newDataStrapi}
            }
            axios(configStrapi)
                .catch((error) => {
                    debugger
                    if (error.response.status === 400) {
                        const data = JSON.parse(error.config.data)
                        const newConfig = {...error.config, method: 'put', url: error.config.url + `?reference=${data.data.reference}`}
                        return axios(newConfig)
                    }
                    else throw error
                })
                .then((response) => {
                    console.log(response)
                    //UPDATE INKO
                    const configInko = {
                        method: 'put',
                        url: newUrl,
                        headers: {'Authorization': countryOrder === 'BE' ? cookies.get('TOKENinko') : cookies.get('TOKENinkoES')},
                        data: newDataInko
                    };
                    axios(configInko)
                        .then((response) => {
                            console.log(response.data);

                            //CONFIG ORDER EMAIL API INKOPRINT
                            const configMail = {
                                method: "post",
                                url: inkoprintUrl + "sendmail",
                                headers: {
                                    'Authorization': countryOrder === 'BE' ? cookies.get('TOKENinko') : cookies.get('TOKENinkoES'),
                                    "Content-Type": "application/json",
                                },
                                data: newDataMail,
                            };
                            //SENDING ORDER EMAIL
                            axios(configMail)
                                .then((response) => {
                                    //DO EVERYTHING ALWAYS
                                    console.log(response)
                                    // navigate(forwardURL);
                                    history.push(forwardURL)
                                })
                                .catch((error) => {
                                    console.log("Order update but email not sent, authentication failed.");
                                    console.log(error);
                                });
                        })
                })
                .catch(error => {
                    const k = error
                    console.log("hello")
                })
        }

        if (statusData.trigger === Statuses.RejectedOrderChecking) {

            var configRejectedInko = {
                method: 'put',
                url: newUrl,
                headers: {'Authorization': countryOrder === 'BE' ? cookies.get('TOKENinko') : cookies.get('TOKENinkoES')},
                data: newDataInko
            };
            //UPDATE INKO
            axios(configRejectedInko)
                .then((response) => {
                    console.log(JSON.stringify(response.data));

                    //CONFIG ORDER EMAIL API INKOPRINT
                    var configMail = {
                        method: "post",
                        url: inkoprintUrl + "sendmail",
                        headers: {
                            'Authorization': countryOrder === 'BE' ? cookies.get('TOKENinko') : cookies.get('TOKENinkoES'),
                            "Content-Type": "application/json",
                        },
                        data: newDataMail,
                    };
                    //SENDING ORDER EMAIL
                    axios(configMail)
                        .then((response) => {
                            //DO EVERYTHING ALWAYS
                            history.push(forwardURL)
                            // window.location.assign(forwardURL);
                        })
                        .catch((error) => {
                            console.log("Order update but email not sent, authentication failed.");
                            console.log(error);
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    function chooseForwardUrl(step, country){
        const suffix = country === 'ES' ? `-${country.toLowerCase()}` : ''
        switch (step) {
            case 'accepted':
                return '/new' + suffix;
                break;
            case 'production':
                return '/in-production' + suffix;
                break;
            case 'shipping':
                return '/shipping' + suffix;
                break;
            default:
                return '/'
        }

    }

    const showConfirm = () => {
        confirm({
            title: `Do you confirm this order ${statusData.buttonText}?`,
            content: `By accepting you will be changing the status of the order: ${statusData.order_id} - ${statusData.buttonText}`,
            onOk() {
                updateOrder()
            },
            onCancel() {
            },
        });
    }

    return <Button type={statusData.type} onClick={showConfirm}>{statusData.buttonText}</Button>;
}